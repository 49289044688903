<template>

  <div>
    <div class="px-2">

      <hr class="mt-3 mb-3" />

      <div v-for="(faq,index) in content" :key="index">
        <section class="promo-card-wrapper mb-3 mt-3">
        <div class="text-light" data-toggle="collapse" :data-target="'#1' +index" aria-expanded="false" :aria-controls="'1' +index">
          <strong style="color: var(--yellow);">{{ faq.title }}</strong>
        </div>
        <div class="promo-content text-light collapse faq-border show" :id="'1'+index">
          <!--Some html static content-->
          {{ faq.description }}
        </div>
      </section>

      <hr class="mt-3 mb-3" />
      </div>

     

      
    </div>


    <!-- End Section -->

    <!-- <section class="social-links" style=" ">
       <div class="text-light width-available text-center">
        <strong style="color: var(--yellow);">
          Frequently Asked Questions
        </strong>
      </div>
    </section> -->
  </div>

</template>

<script>

import axios from "@/services/cms";

export default {
  name: "Faq",
  components: {},
  data() {
    return {
      content: [],
    };
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "terms");
    this.reloadProfile();
    this.getFaq();
  },
  methods: {
    async getFaq() {
      try {
        const response = await axios.get("/get/faq", {
          headers: {
            Accept: "application/json",
          },
        });
        if (response.status === 200) {
          this.content = response.data.faq; // Get the HTML content from the editor
          console.log(response.data);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching frequently asked questions",
          error.response?.data || error.message
        );
      }
    },
  },
};
</script>
